import toResponsiveValue, { ResponsiveValue } from 'lib/toResponsiveValue';
import styled, { css } from 'styled-components';

type Columns = 1 | 2 | 4 | 6 | 8 | 10 | 12;
export type Spans = 1 | 2 | 3 | 4 | 5 | 6 | 8 | 9 | 12;
type RowGap = 16 | 24 | 32 | 48;
type InlineMargin = 0 | 15 | 18 | 38 | 48 | 84;
type Spacing = 'standard' | 'tight' | 'none';

export interface GridProps {
  columns?: Columns | ResponsiveValue<Columns>;
  spacing?: Spacing | ResponsiveValue<Spacing>;
  inlineMargin?: InlineMargin | ResponsiveValue<InlineMargin>;
  rowGap?: RowGap | ResponsiveValue<RowGap>;
}

export type ColumnProps = {
  starts?: number | string | ResponsiveValue<number | string>;
  rowStarts?: number | string | ResponsiveValue<number | string>;
} & (
  | {
      ends?: number | string | ResponsiveValue<number | string>;
      spans?: undefined;
    }
  | {
      ends?: undefined;
      spans?: Spans | ResponsiveValue<Spans>;
    }
) &
  (
    | {
        rowEnds?: number | string | ResponsiveValue<number | string>;
        rowSpans?: undefined;
      }
    | {
        rowEnds?: undefined;
        rowSpans?: Spans | ResponsiveValue<Spans>;
      }
  );

export const Grid = styled.div<GridProps>(
  ({
    columns: c = 12,
    spacing: s = 'standard',
    inlineMargin: m = { xs: 15, s: 18, l: 38, xl: 48 },
    rowGap: r = 0,
    theme: { bp },
  }) => {
    const columns = toResponsiveValue(c);
    const spacing = toResponsiveValue(s);
    const inlineMargin = toResponsiveValue(m);
    const rowGap = toResponsiveValue(r);

    return css`
      display: grid;

      ${bp.xs} {
        grid-template-columns: repeat(
          ${columns.xs < 2 ? 1 : columns.xs < 6 ? 2 : 6},
          1fr
        );
        grid-column-gap: ${spacing.xs === 'none'
          ? 0
          : spacing.xs === 'tight'
          ? 4
          : 15}px;
        margin-inline: ${inlineMargin.xs as number}px;
        row-gap: ${rowGap.xs as number}px;
      }

      ${bp.s} {
        grid-template-columns: repeat(
          ${columns.s < 2 ? 1 : columns.s < 6 ? 2 : 6},
          1fr
        );
        grid-column-gap: ${spacing.s === 'none'
          ? 0
          : spacing.s === 'tight'
          ? 4
          : 18}px;
        margin-inline: ${inlineMargin.s as number}px;
        row-gap: ${rowGap.s as number}px;
      }

      ${bp.m} {
        grid-template-columns: repeat(
          ${columns.m < 2
            ? 1
            : columns.m < 4
            ? 2
            : columns.m < 6
            ? 4
            : columns.m < 12
            ? 6
            : 12},
          1fr
        );
        row-gap: ${rowGap.m as number}px;
      }

      ${bp.l} {
        grid-template-columns: repeat(
          ${columns.l < 2
            ? 1
            : columns.l < 4
            ? 2
            : columns.l < 6
            ? 4
            : columns.l < 12
            ? 6
            : 12},
          1fr
        );
        grid-column-gap: ${spacing.l === 'none'
          ? 0
          : spacing.l === 'tight'
          ? 4
          : 24}px;
        margin-inline: ${inlineMargin.l as number}px;
        row-gap: ${rowGap.l as number}px;
      }

      ${bp.xl} {
        grid-template-columns: repeat(
          ${columns.xl < 2
            ? 1
            : columns.xl < 4
            ? 2
            : columns.xl < 6
            ? 4
            : columns.xl < 12
            ? 6
            : 12},
          1fr
        );
        margin-inline: ${inlineMargin.xl as number}px;
        row-gap: ${rowGap.xl as number}px;
      }

      ${bp.xxl} {
        grid-template-columns: repeat(
          ${columns.xxl < 2
            ? 1
            : columns.xxl < 4
            ? 2
            : columns.xxl < 6
            ? 4
            : columns.xxl < 12
            ? 6
            : 12},
          1fr
        );
        margin-inline: ${inlineMargin.xxl as number}px;
        row-gap: ${rowGap.xxl as number}px;
      }
    `;
  }
);

export const Column = styled.div<ColumnProps>(
  ({
    starts: s = 'auto',
    rowStarts: rs = 'auto',
    ends: e = 'auto',
    rowEnds: re = 'auto',
    spans: p,
    rowSpans: rp,
    theme: { bp },
  }) => {
    const starts = toResponsiveValue(s);
    const rowStarts = toResponsiveValue(rs);
    const ends = toResponsiveValue(e);
    const rowEnds = toResponsiveValue(re);
    const spans = toResponsiveValue(p);
    const rowSpans = toResponsiveValue(rp);

    return css`
      ${bp.xs} {
        grid-column-start: ${starts.xs};
        grid-column-end: ${spans.xs ? `span ${spans.xs}` : ends.xs};
        grid-row-start: ${rowStarts.xs};
        grid-row-end: ${rowSpans.xs ? `span ${rowSpans.xs}` : rowEnds.xs};
      }

      ${bp.s} {
        grid-column-start: ${starts.s};
        grid-column-end: ${spans.s ? `span ${spans.s}` : ends.s};
        grid-row-start: ${rowStarts.s};
        grid-row-end: ${rowSpans.s ? `span ${rowSpans.s}` : rowEnds.s};
      }

      ${bp.m} {
        grid-column-start: ${starts.m};
        grid-column-end: ${spans.m ? `span ${spans.m}` : ends.m};
        grid-row-start: ${rowStarts.m};
        grid-row-end: ${rowSpans.m ? `span ${rowSpans.m}` : rowEnds.m};
      }

      ${bp.l} {
        grid-column-start: ${starts.l};
        grid-column-end: ${spans.l ? `span ${spans.l}` : ends.l};
        grid-row-start: ${rowStarts.l};
        grid-row-end: ${rowSpans.l ? `span ${rowSpans.l}` : rowEnds.l};
      }

      ${bp.xl} {
        grid-column-start: ${starts.xl};
        grid-column-end: ${spans.xl ? `span ${spans.xl}` : ends.xl};
        grid-row-start: ${rowStarts.xl};
        grid-row-end: ${rowSpans.xl ? `span ${rowSpans.xl}` : rowEnds.xl};
      }

      ${bp.xxl} {
        grid-column-start: ${starts.xxl};
        grid-column-end: ${spans.xxl ? `span ${spans.xxl}` : ends.xxl};
        grid-row-start: ${rowStarts.xxl};
        grid-row-end: ${rowSpans.xxl ? `span ${rowSpans.xxl}` : rowEnds.xxl};
      }
    `;
  }
);
