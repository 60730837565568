import type { State } from 'store/types';
import { AuthState } from 'store/auth/types';
import { createSelector } from '@reduxjs/toolkit';

export const selectAuth = (state: State): AuthState => state.auth;

export const selectCustomerIsLoggedIn = createSelector(
  selectAuth,
  ({ accessToken, refreshToken, anonymousId }: AuthState): boolean =>
    Boolean(accessToken && refreshToken && !anonymousId)
);

export const selectCustomerIsLoggedOut = createSelector(
  selectAuth,
  ({ accessToken, anonymousId }: AuthState): boolean =>
    anonymousId !== '' || accessToken === ''
);
