import Picture from 'components/Media/Picture';
import Video from 'components/Media/Video';
import toResponsiveValue, {
  BreakpointKey,
  ResponsiveValue,
} from 'lib/toResponsiveValue';
import { MediaModel, MediaSourceModel } from 'models/contentful/media/types';
import { FC } from 'react';
import { useSelector } from 'store/hooks';
import styled, { DefaultTheme, css, useTheme } from 'styled-components';

export interface MediaSourceMapping {
  sources: Record<BreakpointKey, MediaSourceModel>;
  types: { image: BreakpointKey[]; video: BreakpointKey[] };
}

interface PictureSourcesProps {
  sizes: BreakpointKey[];
  sources: MediaSourceMapping['sources'];
  vwRelativeWidth?: ResponsiveValue<number> | undefined;
  theme: DefaultTheme;
}

export const collateMediaByType = (
  sizes: Record<BreakpointKey, MediaSourceModel | null | undefined>
) => {
  const responsiveSizes = toResponsiveValue(sizes);

  const sources = Object.entries(responsiveSizes).reduce<MediaSourceMapping>(
    (acc, [key, value]) => {
      if (!value) {
        return acc;
      }

      const size = key as BreakpointKey;

      const type =
        value?.lowResolutionVideo || value?.highResolutionVideo
          ? 'video'
          : 'image';

      acc.sources[size] = value;

      if (type === 'image') {
        acc.types.image.push(size);
      }

      if (type === 'video') {
        acc.types.video.push(size);
      }

      return acc;
    },
    {
      sources: {} as MediaSourceMapping['sources'],
      types: { image: [], video: [] },
    }
  );

  return sources;
};

export const buildPictureSources = ({
  sizes,
  sources,
  vwRelativeWidth: vrw,
  theme,
}: PictureSourcesProps) => {
  const { bpRange, bpSrcSet } = theme;
  const vwRelativeWidth = toResponsiveValue(vrw);

  return sizes.map(size => {
    const width = Math.round(
      (size === 'xxl' ? bpSrcSet.xl : bpSrcSet[size]) *
        (vwRelativeWidth?.[size] || 1)
    );

    return (
      <source
        key={size}
        media={bpRange[size]}
        srcSet={`${sources[size].image.url}?w=${width}&auto=format`}
        width={sources[size].image.width as number}
        height={sources[size].image.height as number}
      />
    );
  });
};

const VideoContainer = styled.div<MediaSourceMapping>(
  ({ sources, types, theme: { bp } }) => css`
    display: none;
    width: 100%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    overflow: hidden;

    ${Object.keys(bp).map(bpKey => {
      const b = bpKey as BreakpointKey;

      if (sources[b] && types.video.includes(b)) {
        return css`
          ${bp[b]} {
            display: block;
            background-image: url(${sources[b].image.url}?auto=compressformat);
            aspect-ratio: ${sources[b].image.width} / ${sources[b].image.height};
          }
        `;
      }

      return css`
        ${bp[b]} {
          display: none;
        }
      `;
    })}
  `
);

const Media: FC<
  MediaModel & { vwRelativeWidth?: ResponsiveValue<number>; alt?: string }
> = ({ showVideoControls, xs, s, m, l, xl, xxl, vwRelativeWidth, alt }) => {
  const { sources, types } = collateMediaByType({ xs, s, m, l, xl, xxl });
  const theme = useTheme();

  const bp = useSelector(state => state.dimensions.breakpoint);
  const largestVideo = types.video.slice(-1)[0];

  return (
    <>
      {types.image.length ? (
        <Picture sizes={types.image}>
          {buildPictureSources({
            sizes: types.image,
            sources,
            vwRelativeWidth,
            theme,
          })}
          <img
            alt={alt || ''}
            src={
              (bp ? sources[bp].image.url : sources.xs.image.url) +
              '?w=32&auto=compress,format'
            }
            width={0}
            height={0}
          />
        </Picture>
      ) : null}
      <VideoContainer types={types} sources={sources}>
        {bp && types.video.includes(bp) ? (
          <Video
            key={bp}
            image={sources[bp].image}
            highResolutionVideo={sources[bp].highResolutionVideo}
            lowResolutionVideo={sources[bp].lowResolutionVideo}
            showVideoControls={showVideoControls}
          />
        ) : null}
      </VideoContainer>
      {!bp && largestVideo ? (
        <video
          autoPlay={false}
          controls={false}
          preload="none"
          width={0}
          height={0}
        >
          <source
            src={[
              sources[largestVideo].highResolutionVideo,
              sources[largestVideo].lowResolutionVideo,
            ]
              .map(source => source?.url)
              .find((url): url is string => Boolean(url))}
          />
        </video>
      ) : null}
    </>
  );
};

export default Media;
