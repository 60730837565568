import { Spinner } from 'components/Icons';
import { FC, memo } from 'react';
import styled, { DefaultTheme } from 'styled-components';

interface SpinnerWithTextProps {
  color?: keyof DefaultTheme['colors'];
  children: string;
}

const SpinnerWithTextContainer = styled.span`
  display: flex;
  align-items: center;
`;

const OffsetText = styled.span`
  margin-left: 2px;
`;

const SpinnerWithText: FC<SpinnerWithTextProps> = memo(
  ({ children, color }) => (
    <SpinnerWithTextContainer>
      <Spinner strokeColor={color} />
      <OffsetText>{children.trim()}</OffsetText>
    </SpinnerWithTextContainer>
  )
);

export default SpinnerWithText;
