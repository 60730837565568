import {
  MediaAssetModel,
  MediaSourceModel,
} from 'models/contentful/media/types';
import { FC, useEffect, useState } from 'react';

interface VideoProps extends MediaSourceModel {
  showVideoControls?: boolean;
  initialSource?: MediaAssetModel | null;
}

export type BandwidthSupportingNavigator = Partial<
  Navigator & {
    connection?: { effectiveType?: string };
  }
>;

type PickVideoSourceByBandwidth = (args: {
  highResolutionVideo?: MediaAssetModel | null;
  lowResolutionVideo?: MediaAssetModel | null;
  setSource: (source: MediaAssetModel) => void;
  navigator?: Navigator;
}) => () => void;

export const pickVideoSourceByBandwidth: PickVideoSourceByBandwidth =
  ({ highResolutionVideo, lowResolutionVideo, setSource, navigator: n }) =>
  () => {
    const nav = n as BandwidthSupportingNavigator | undefined;

    if (!highResolutionVideo && !lowResolutionVideo) {
      return;
    }

    if (highResolutionVideo && !lowResolutionVideo) {
      setSource(highResolutionVideo);
      return;
    }

    if (lowResolutionVideo && !highResolutionVideo) {
      setSource(lowResolutionVideo);
      return;
    }

    if (
      !nav?.connection?.effectiveType ||
      nav?.connection?.effectiveType === '4g'
    ) {
      setSource(highResolutionVideo as MediaAssetModel);
      return;
    }

    setSource(lowResolutionVideo as MediaAssetModel);
  };

const Video: FC<VideoProps> = ({
  image: poster,
  showVideoControls,
  highResolutionVideo,
  lowResolutionVideo,
  initialSource = null,
}) => {
  const [source, setSource] = useState<MediaAssetModel | null>(initialSource);

  useEffect(
    pickVideoSourceByBandwidth({
      highResolutionVideo,
      lowResolutionVideo,
      navigator,
      setSource,
    }),
    [highResolutionVideo?.url, lowResolutionVideo?.url]
  );

  if (!source?.url) {
    return null;
  }

  return (
    <video
      loop={true}
      autoPlay={true}
      muted={process.env.NODE_ENV !== 'test'}
      playsInline={true}
      controls={showVideoControls}
      poster={poster.url + '?auto=compress,format'}
      width="100%"
      height="auto"
    >
      <source type="video/mp4" src={source.url} />
    </video>
  );
};

export default Video;
