import { NavigationContext } from 'components/NavigationProvider';
import { useContext } from 'react';

const useNavigation = () => {
  const context = useContext(NavigationContext);

  if (context === undefined) {
    throw new Error('useNavigation must be used within a NavigationProvider');
  }
  return context;
};

export default useNavigation;
