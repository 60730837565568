import { ComponentProps, forwardRef, ReactNode } from 'react';
import styled, { css, DefaultTheme } from 'styled-components';

type FormButtonMode = 'light' | 'dark';

type ColorMappings = {
  backgroundColor: keyof DefaultTheme['colors'];
  color: keyof DefaultTheme['colors'];
  borderColor: keyof DefaultTheme['colors'];
};

type ColorMap = Record<
  FormButtonMode,
  {
    enabled: ColorMappings;
    disabled: ColorMappings;
  }
>;

export interface FormButtonProps
  extends Omit<ComponentProps<'button'>, 'children'> {
  as?: string;
  mode?: FormButtonMode;
  children: ReactNode | ((colors: ColorMappings) => ReactNode);
  className?: string;
  disabled?: boolean;
  type?: 'button' | 'submit';
}

const colorMap: ColorMap = {
  light: {
    enabled: {
      backgroundColor: 'white',
      color: 'black',
      borderColor: 'black',
    },
    disabled: {
      backgroundColor: 'white',
      color: 'grey6',
      borderColor: 'grey2',
    },
  },
  dark: {
    enabled: {
      backgroundColor: 'black',
      color: 'white',
      borderColor: 'black',
    },
    disabled: {
      backgroundColor: 'grey0',
      color: 'grey6',
      borderColor: 'grey0',
    },
  },
};

const FormButtonContainer = styled.button<
  Pick<Required<FormButtonProps>, 'mode'>
>(
  ({ theme: { colors }, mode, disabled }) => css`
    appearance: none;
    justify-content: center;
    align-items: center;
    display: flex;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    width: 100%;
    padding: 0 8px;
    height: 56px;
    transition: background-color 200ms, border-color 200ms, color 200ms;
    border-width: 1px;
    border-style: solid;
    border-radius: 10px;
    user-select: none;
    text-decoration: none;

    cursor: ${disabled ? 'not-allowed' : 'pointer'};
    border-color: ${colors[
      colorMap[mode][disabled ? 'disabled' : 'enabled'].borderColor
    ]};
    background-color: ${colors[
      colorMap[mode][disabled ? 'disabled' : 'enabled'].backgroundColor
    ]};
    color: ${colors[colorMap[mode][disabled ? 'disabled' : 'enabled'].color]};
  `
);

const FormButton = forwardRef<HTMLButtonElement, FormButtonProps>(
  (
    {
      as,
      children,
      className,
      mode = 'dark',
      disabled = false,
      type = 'submit',
      ...htmlButtonProps
    },
    ref
  ) => {
    const colors = colorMap[mode][disabled ? 'disabled' : 'enabled'];

    return (
      <FormButtonContainer
        {...htmlButtonProps}
        as={as as undefined}
        ref={ref}
        mode={mode}
        disabled={disabled}
        type={type}
        className={className}
      >
        {typeof children === 'function' ? children(colors) : children}
      </FormButtonContainer>
    );
  }
);

export default FormButton;
