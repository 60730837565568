import type { BreakpointKey } from 'lib/toResponsiveValue';
import styled, { css } from 'styled-components';

const Picture = styled.picture<{
  sizes?: BreakpointKey[];
}>(
  ({ theme: { bpRange }, sizes = [] }) => css`
    display: none;
    width: 100%;

    > img {
      width: 100%;
      height: 100%;
    }

    ${sizes.map(
      size => css`
        @media screen and ${bpRange[size]} {
          display: flex;
        }
      `
    )}
  `
);

export default Picture;
