import { FC, SVGProps } from 'react';
import styled from 'styled-components';

const LogoContainer = styled.svg`
  path {
    transition: fill 300ms;
  }
`;

const Logo: FC<SVGProps<SVGElement>> = ({ className }) => (
  <LogoContainer
    className={className}
    width="200"
    height="28"
    viewBox="0 0 200 28"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2408 -0.000244141L9.65249 9.29199H1.30632L6.64074 14.0112L0.000244141 24.0348L10.4509 18.9132L10.8136 16.8283L4.71766 19.901L8.92698 13.9381L5.33467 10.7553H10.995L14.1525 5.26791L14.6238 10.7553H21.4096L15.7853 13.9381L16.5833 21.9132L12.8096 17.0479L12.4829 19.2063L18.7246 27.4373L17.6719 14.3769L26.8165 9.29199H16.3655L15.2408 -0.000244141Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.1225 21.2247H51.9221L52.9464 20.2472V10.5358L51.9221 9.27209H56.6812C57.0031 9.27209 57.325 9.25535 57.6298 9.27209C58.4934 9.34066 59.4084 9.59658 60.0691 10.4333C60.2889 10.7064 60.6954 11.3554 60.6954 12.3117C60.6954 12.7896 60.5262 14.0529 59.459 14.8725C59.1541 15.0945 58.646 15.3166 58.5108 15.3847L60.5938 19.2949C61.0173 20.0978 61.2375 20.4394 61.576 20.7125L62.2201 21.2247H61.2711C61.0683 21.2076 60.2043 21.1909 59.5946 20.6953C59.3067 20.4562 59.0695 20.0978 58.697 19.4149L56.6812 15.7435H55.0217V20.2688L56.1225 21.2247ZM56.0209 14.4974C56.8003 14.4802 57.5962 14.3778 58.0873 13.6774C58.3242 13.3362 58.4428 12.9088 58.4428 12.5166C58.4428 12.3284 58.4258 11.799 58.0873 11.3211C57.5622 10.5872 56.8169 10.57 56.0043 10.5529H55.0217V14.4974H56.0209Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.18 21.2333H29.9246L30.9715 20.2798V10.5933L29.9246 9.3165H34.4335C35.8011 9.3165 37.1185 9.2823 38.0808 10.5419C38.3007 10.8484 38.6719 11.4782 38.6719 12.4488C38.6719 12.9766 38.5707 14.0831 37.5233 14.9852C36.308 16.0411 34.9229 16.0411 34.4671 16.0411H33.0659V20.2798L34.18 21.2333ZM33.0659 10.5762V14.7643H34.18C34.7541 14.7643 36.578 14.5598 36.578 12.5341C36.578 12.3296 36.578 11.768 36.1893 11.2741C35.8687 10.8827 35.3622 10.5933 34.3489 10.5762H33.0659Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49.1328 11.6059V9.32471H40.9429L41.9392 10.5847V20.2541L40.9429 21.2247H49.1162V18.8925L47.968 19.9308H44.0332V15.5729H47.1064L48.0016 16.5941V13.2402L47.1235 14.2957H44.0332V10.5847H47.8498L49.1328 11.6059Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M70.9527 10.576H67.4235V14.3384H70.9693L71.7968 13.2829V16.6368L71.0369 15.598H67.4235V20.2797L68.5041 21.2331H64.3328L65.3461 20.2797V10.5589L64.3328 9.31641H72.2527V11.5972L70.9527 10.576Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M82.9928 11.6059V9.32471H74.803L75.7993 10.5847V20.2541L74.803 21.2247H82.9762V18.8925L81.828 19.9308H77.8932V15.5729H80.9665L81.8612 16.5941V13.2402L80.9835 14.2957H77.8932V10.5847H81.7094L82.9928 11.6059Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M95.3054 11.0273C94.6301 10.4142 93.4815 10.2612 92.6204 10.2612C92.3333 10.2612 91.4046 10.2783 90.5435 10.6016C88.5843 11.3167 87.588 13.0701 87.588 15.113C87.588 15.4367 87.588 16.2877 87.926 17.173C88.3143 18.2289 89.5301 20.2714 92.5532 20.2714C92.7386 20.2714 94.4443 20.2032 95.4743 19.3012L96.2172 18.6542V20.7991C95.8625 20.9183 95.5249 21.0375 95.1536 21.1395C94.6131 21.293 93.4649 21.5314 91.9787 21.5314C90.3406 21.5314 88.9726 21.242 87.639 20.2032C86.8455 19.5734 85.3762 18.0754 85.3762 15.4024C85.3762 14.4661 85.5451 12.3722 87.4702 10.7208C89.4289 9.0526 91.5904 9.01831 92.435 9.01831C94.2755 9.01831 95.4573 9.37586 96.2002 9.58035V11.8616L95.3054 11.0273Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M105.682 21.2333H101.477L102.524 20.4162V10.5929H99.8558L98.606 11.5974V9.31616H108.502V11.5974L107.37 10.5929H104.618V20.4162L105.682 21.2333Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M130.006 21.2333H125.819L126.831 20.297L126.055 12.1084L122.272 21.2333H121.648L117.916 12.1084L117.206 20.297L118.22 21.2333H114.876L115.923 20.297L116.767 10.6272L115.805 9.31616H118.963L122.508 17.9819L126.156 9.31616H128.993L128.048 10.61L128.959 20.297L130.006 21.2333Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M142.931 19.3861C141.141 21.4972 138.793 21.5314 137.915 21.5314C137.223 21.5314 135.585 21.4633 133.947 20.2033C133.103 19.5563 131.678 18.1265 131.678 15.4025C131.678 11.487 134.488 9.0188 138.202 9.0188C138.895 9.0188 140.617 9.06941 142.205 10.2955C143.066 10.9592 144.332 12.5082 144.349 15.3004C144.349 16.0323 144.282 17.7861 142.931 19.3861ZM139.959 10.8572C139.638 10.6698 138.946 10.3294 137.949 10.3294C137.629 10.3294 136.869 10.3804 136.075 10.8061C134.505 11.64 133.897 13.4445 133.897 15.147C133.897 15.3853 133.914 16.0665 134.083 16.7816C134.302 17.5474 135.213 20.2882 138.152 20.2882C139.824 20.2882 141.158 19.318 141.749 17.769C142.036 16.9686 142.087 16.1514 142.087 15.8625C142.221 13.0192 141.023 11.4698 139.959 10.8572Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M160.854 21.2333H156.666L157.679 20.297L156.902 12.1084L153.12 21.2333H152.495L148.763 12.1084L148.054 20.297L149.067 21.2333H145.724L146.77 20.297L147.615 10.6272L146.653 9.31616H149.811L153.356 17.9819L157.004 9.31616H159.841L158.895 10.61L159.807 20.297L160.854 21.2333Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M171.646 11.6059V9.32471H163.457L164.452 10.5847V20.2541L163.457 21.2247H171.63V18.8925L170.481 19.9308H166.547V15.5729H169.62L170.515 16.5941V13.2402L169.637 14.2957H166.547V10.5847H170.363L171.646 11.6059Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M185.893 10.1506V21.2845H184.88L177.011 11.5466V20.3482L177.923 21.2845H174.698L175.61 20.3482V10.627L174.63 9.3335H177.804L184.525 17.8114V10.1506L183.563 9.3335H186.889L185.893 10.1506Z"
      fill="black"
    />
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="189"
      y="9"
      width="11"
      height="13"
    >
      <path
        d="M189.479 9.31616H199.375V21.2333H189.479V9.31616Z"
        fill="black"
      />
    </mask>
    <g mask="url(#mask0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M196.555 21.2333H192.35L193.397 20.4162V10.5929H190.729L189.479 11.5974V9.31616H199.375V11.5974L198.244 10.5929H195.491V20.4162L196.555 21.2333Z"
        fill="black"
      />
    </g>
  </LogoContainer>
);

export default styled(Logo)``;
